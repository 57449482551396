import React from 'react';
import {Link} from "gatsby";
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"
import {I18nextContext} from "gatsby-plugin-react-i18next"

export const BackToFaqHome = () => {
  const context = React.useContext(I18nextContext);
  const text = (context.language === 'jp') ? 'よくある質問にもどる' : 'Back to FAQs';

  return (
    <p className="text-center">
      <Link to="/akamaru/faq" className="btn btn-secondary">
        {text}
      </Link>
    </p>
  )
}

const FaqPage = ({title, children}) => {
  return (
    <AppLayout>
      <Seo title={title} isIndex={false}/>
      <article className="container page-article-main faq-page privacy-page">
        <section
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <h1 itemProp="name">{title}</h1>
          <div
            itemScope=""
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            <div itemProp="text">
              {children}
            </div>
          </div>
        </section>

        <BackToFaqHome/>

      </article>
    </AppLayout>
  )
}

export const FaqItem = ({title, children}) => {
  return (
    <>
      <article className="container page-article-main faq-page privacy-page">
        <section
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <p itemProp="name">Q. {title}</p>
          <div
            itemScope=""
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            <div itemProp="text">
              {children}
            </div>
          </div>
        </section>

      </article>
    </>
  );
}

export default FaqPage;
