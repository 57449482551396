import * as React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"
import FaqPage from "src/components/common/faqPage";

const Faq0004 = () => {
  const {t} = useTranslation();
  return (
    <FaqPage title={t("course.checkYourCourse.title")}>
      
      <p>{t("course.checkYourCourse.lead")}</p>

      <>
        <h3>{t("course.checkYourCourse.contents.section1.title")}</h3>
        <ol>
          <li>{t("course.checkYourCourse.contents.section1.item1")}</li>
          <li>{t("course.checkYourCourse.contents.section1.item2")}</li>
          <li>{t("course.checkYourCourse.contents.section1.item3")}</li>
          <li>{t("course.checkYourCourse.contents.section1.item4")}</li>
        </ol>
      </>

      <>
        <h3>{t("course.checkYourCourse.contents.section2.title")}</h3>
        <ol>
          <li>{t("course.checkYourCourse.contents.section2.item1")}</li>
          <li>{t("course.checkYourCourse.contents.section2.item2")}</li>
          <li>{t("course.checkYourCourse.contents.section2.item3")}</li>
          <li>{t("course.checkYourCourse.contents.section2.item4")}</li>
        </ol>
      </>

      <>
        <h3>{t("course.checkYourCourse.contents.section3.title")}</h3>
        <ol>
          <li>{t("course.checkYourCourse.contents.section3.item1")}</li>
          <li>{t("course.checkYourCourse.contents.section3.item2")}</li>
          <li>{t("course.checkYourCourse.contents.section3.item3")}</li>
          <li>{t("course.checkYourCourse.contents.section3.item4")}</li>
        </ol>
      </>
    </FaqPage>
  );
}

export default Faq0004

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
