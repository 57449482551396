import * as React from "react"
import PropTypes from "prop-types"
import "src/styles/reset.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "src/styles/styles.scss"
import "src/styles/app_common.scss"
import Seo from "src/components/seo";
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

const AppHeader = () => {
  const {languages, originalPath} = useI18next();

  return (
    <header>
      <ul className="languages">
        {languages.map((language) => (
          <li key={language}>
            <Link to={originalPath} language={language}>
              { (language === 'jp') && "日本語" }
              { (language === 'en') && "English" }
            </Link>
          </li>
        ))}
      </ul>
    </header>
  );
}

const AppLayout = ({ children, isShownHeader }) => {
  return (
    <>
      <Seo isIndex={false} />

      { isShownHeader && <AppHeader/> }
      <main className="page-main-contents">{children}</main>
    </>
  )
}

export default AppLayout

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
  isShownHeader: PropTypes.bool,
}

AppLayout.defaultProps = {
  isShownHeader: true
}